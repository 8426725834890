@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&family=Poiret+One&display=swap');
* {
  font-family: "Kumbh Sans", sans-serif;
  line-height: 1.65;
  letter-spacing: 0.16px;
  cursor: pointer;
}


input{

  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  vertical-align: baseline;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: .16px;
  border-radius: 0;
  outline: 2px solid transparent;
  outline-offset: -2px;
  height: 40px;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  background-color: #f4f4f4;
  padding: 0 16px;
  color: #161616;
  transition: background-color 70ms cubic-bezier(.2,0,.38,.9),outline 70ms cubic-bezier(.2,0,.38,.9);  
  

}

input:focus{
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
}





.button2 {
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #090909;
  padding: 0.7em 1.7em;
  cursor: pointer;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  /* box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff; */
}

.button2:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

.button2:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #009087;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.button2:hover {
  color: #ffffff;
  border: 1px solid #009087;
}

.button2:hover:before {
  top: -35%;
  background-color: #24262b;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button2:hover:after {
  top: -45%;
  background-color: #24262b;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}
/* .navbar{
  transition: all .5s ease-in-out;
} */
.slide-down {
  animation: slide-down 2s linear ;
}

.slide-up {
  animation: slide-up 2s linear;
}
[data-target="slide-content"] {
  height: auto;
  overflow: auto;
  visibility: visible;
}


@keyframes slide-down {
  0% {
    visibility: hidden;
    height: 0;
  }

  95% {
    visibility: visible;
    height: 100%;
  }

  /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
  100% {
    visibility: visible;
    height: 100%;
  }
}

@keyframes slide-up {
  from {
    visibility: visible;
    height: 100%;
  }

  to {
    visibility: hidden;
    height: 0;
  }
}

.desktop{
  display: flex !important;
}
.mobile{
  display: none !important;
}
.navbar div div:hover{
transform: scale(1.02);
}
/* .show{
  display: block !important;
} */

@media screen and (max-width:1064px) { 
  .desktop{
    display: none !important;
  }
  .mobile{
    display: flex !important;
  }
  .header{
    flex-direction: column !important;
  }
  .services{
    grid-template-columns: 1fr !important;
  }
  /* .services div{
    width: 100% !important;
    margin-bottom: 15px ;
  } */
  .contact{
    flex-direction: column !important;
  }
  .contact div:nth-child(1){
    width: 100% !important;
  }
  .contact div:nth-child(2){
    width: 85% !important;
  }
  .mission {
    flex-direction: column !important;
  }
  .vision {
    flex-direction: column-reverse !important;
  }
  .vision div,.mission div {
    width: 100% !important;
  }
  .vision img,.mission img {
    width: 100% !important;
  }
  .contact div img{
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(1){
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(2){
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(3){
    width: 100% !important;
  }
  /* .cards{
    justify-content: center !important;
  } */
  /* .show{
    display: none !important;
  } */
}
@media screen and (max-width:425px) { 
  .m0{
    margin:0 !important;
    border-radius: 0 !important;
  }
  .services{
    grid-template-columns: 1fr !important;
  }
  /* .services div{
    width: 100% !important;
    margin-bottom: 15px ;
  } */
  .contact,.show{
    flex-direction: column !important;
  }
  .contact div:nth-child(1){
    width: 100% !important;
  }
  .contact div:nth-child(2){
    width: 85% !important;
  }
  .mission {
    flex-direction: column !important;
  }
  .vision {
    flex-direction: column-reverse !important;
  }
  .vision div,.mission div {
    width: 100% !important;
  }
  .vision img,.mission img {
    width: 100% !important;
  }
  .contact div img{
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(1){
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(2){
    width: 100% !important;
  }
  footer  div:nth-child(1) div:nth-child(3){
    width: 100% !important;
  }
  /* .cards{
    justify-content: center !important;
  } */
  .fc{
    flex-direction: column !important;
  }
  /* .show{
    display: none !important;
  } */
}







.slider {
  position: relative;
  /* margin: 20px 8%;  */
/* Adjust the height to fit your needs */
  overflow: hidden;
}

.slides {
  display: flex;
  width: 100%;
  height: 100%;
}

.slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  color: #0000007d;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}